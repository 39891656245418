import { constantRoutes } from '@/router';
import { getRouters } from '@/api/menu';
import Layout from '@/layout/index';
var permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // 向后端请求路由数据
        getRouters().then(function (res) {
          var accessedRoutes = filterAsyncRouter(res.data);
          commit('SET_ROUTES', accessedRoutes);
          resolve(accessedRoutes);
        });
      });
    }
  }
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}

//export const loadView = (view) => { // 路由懒加载
//  return () => import(`@/views/${view}`)
//}

export var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
export default permission;