import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/usr/local/deploy/web/dragon-egg-admin-nb/node_modules/_@babel_runtime-corejs2@7.23.2@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import router from "./router";
import store from "./store";
import { Message } from 'element-ui';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';
NProgress.configure({
  showSpinner: false
});
var whiteList = ['/login', '/auth-redirect', '/bind', '/register'];
router.beforeEach(function (to, from, next) {
  NProgress.start();
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({
        path: '/'
      });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(function (res) {
          // 拉取user_info
          var roles = res.roles;
          store.dispatch('GenerateRoutes', {
            roles: roles
          }).then(function (accessRoutes) {
            // 测试 默认静态页面
            // store.dispatch('permission/generateRoutes', { roles }).then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes); // 动态添加可访问路由表
            next(_objectSpread(_objectSpread({}, to), {}, {
              replace: true
            })); // hack方法 确保addRoutes已完成
          });
        }).catch(function (err) {
          store.dispatch('FedLogOut').then(function () {
            Message.error(err);
            next({
              path: '/'
            });
          });
        });
      } else {
        next();
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        // if (hasPermission(store.getters.roles, to.meta.roles)) {
        //   next()
        // } else {
        //   next({ path: '/401', replace: true, query: { noGoBack: true }})
        // }
        // 可删 ↑
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});
router.afterEach(function () {
  NProgress.done();
});