import { login, logout, getInfo } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
var user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var username = userInfo.username.trim();
      var password = userInfo.password;
      var code = userInfo.code;
      var uuid = userInfo.uuid;
      return new Promise(function (resolve, reject) {
        login(username, password, code, uuid).then(function (res) {
          setToken(res.token);
          commit('SET_TOKEN', res.token);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit,
        state = _ref2.state;
      return new Promise(function (resolve, reject) {
        getInfo(state.token).then(function (res) {
          var user = res.user;
          var avatar = user.avatar == "" ? require("@/assets/image/profile.png") : process.env.VUE_APP_BASE_API + user.avatar;
          if (res.roles && res.roles.length > 0) {
            // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles);
            commit('SET_PERMISSIONS', res.permissions);
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT']);
          }
          commit('SET_NAME', user.username);
          commit('SET_AVATAR', avatar);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 退出系统
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve, reject) {
        logout(state.token).then(function () {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('SET_PERMISSIONS', []);
          removeToken();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    }
  }
};
export default user;