import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error/401');
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: 'index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/index');
    },
    name: '首页',
    meta: {
      title: '首页',
      icon: 'dashboard',
      noCache: true,
      affix: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'profile',
    component: function component() {
      return import('@/views/system/user/profile/index');
    },
    name: '个人中心',
    meta: {
      title: '个人中心',
      icon: 'user'
    }
  }]
}, {
  path: '/dict',
  component: Layout,
  hidden: true,
  children: [{
    path: 'type/data/:dictId(\\d+)',
    component: function component() {
      return import('@/views/system/dict/data');
    },
    name: '字典数据',
    meta: {
      title: '字典数据',
      icon: ''
    }
  }]
}, {
  path: '/agent',
  component: Layout,
  hidden: true,
  children: [{
    path: 'detail',
    component: function component() {
      return import('@/views/agent/detail/index');
    },
    name: '收入详情',
    meta: {
      title: '收入详情',
      icon: 'user'
    }
  }, {
    path: 'add',
    component: function component() {
      return import('@/views/agent/add/index');
    },
    name: '新增代理商',
    meta: {
      title: '一级代理商',
      icon: 'user'
    }
  }, {
    path: 'second',
    component: function component() {
      return import('@/views/agent/second/second_agent');
    },
    name: '次级代理商',
    meta: {
      title: '次级代理商',
      icon: 'user'
    }
  }]
}, {
  path: '/marketing',
  component: Layout,
  hidden: true,
  children: [{
    path: 'add',
    component: function component() {
      return import('@/views/marketing/activity/add');
    },
    name: '新增活动',
    meta: {
      title: '新增活动',
      icon: ''
    }
  }, {
    path: 'winner',
    component: function component() {
      return import('@/views/marketing/activity/winner');
    },
    name: '中奖名单',
    meta: {
      title: '中奖名单',
      icon: ''
    }
  }]
}, {
  path: '/shop',
  component: Layout,
  hidden: true,
  children: [{
    path: 'shop_income_detail',
    component: function component() {
      return import('@/views/shop/shop_income_detail');
    },
    name: '门店收益详情',
    meta: {
      title: '门店收益详情',
      icon: ''
    }
  }]
}];
export default new Router({
  mode: 'history',
  // 去掉url中的#
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});