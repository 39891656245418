//
//
//
//
//
//

export default {
  name: 'RuoYi',
  data: function data() {
    return {
      url: 'https://gitee.com/y_project/RuoYi-Vue'
    };
  },
  methods: {
    handleDownload: function handleDownload() {
      window.open(this.url);
    }
  }
};